import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'
function getMonth (month) {
    month = parseInt(month)
    if (month === 1) {
        return i18n.locale === 'bn' ? 'জুলাই' : 'July'
    } else if (month === 2) {
        return i18n.locale === 'bn' ? 'আগস্ট' : 'August'
    } else if (month === 3) {
        return i18n.locale === 'bn' ? 'সেপ্টেম্বর' : 'September'
    } else if (month === 4) {
        return i18n.locale === 'bn' ? 'অক্টোবর' : 'October'
    } else if (month === 5) {
        return i18n.locale === 'bn' ? 'নভেম্বর' : 'November'
    } else if (month === 6) {
        return i18n.locale === 'bn' ? 'ডিসেম্বর' : 'December'
    } else if (month === 7) {
        return i18n.locale === 'bn' ? 'জানুয়ারী' : 'January'
    } else if (month === 8) {
        return i18n.locale === 'bn' ? 'ফেব্রুয়ারী' : 'February'
    } else if (month === 9) {
        return i18n.locale === 'bn' ? 'মার্চ' : 'March'
    } else if (month === 10) {
        return i18n.locale === 'bn' ? 'এপ্রিল' : 'April'
    } else if (month === 11) {
        return i18n.locale === 'bn' ? 'মে' : 'May'
    } else if (month === 12) {
        return i18n.locale === 'bn' ? 'জুন' : 'June'
    }
  }
function getTotalData (data, fertilizerId) {
  let total = 0
  data.forEach((element, key) => {
    if (element.fertilizer_id === fertilizerId) {
      total += element.total_quantity
    }
  })
  return total
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, search, fertilizerNameList) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const allRowsHead = [
          [
            { text: vm.$t('fertilizerReport.region'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: search.allo, style: 'td', alignment: 'left' }
          ]
        ]
        if (search.monthList.length) {
          allRowsHead.push([
            { text: vm.$t('fertilizerSales.allocationMonth'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: search.monthList, alignment: 'left', style: 'search' }
          ])
        }
        allRowsHead.push([
          { text: vm.$t('fertilizerSales.allocationYear'), style: 'search', alignment: 'right' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: search.YearList, style: 'search', alignment: 'left' }
        ])
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['49%', '2%', '49%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
        // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const headList = [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerReport.dealer_name'), style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerSales.month'), style: 'th', alignment: 'center' }
        ]
        fertilizerNameList.map((item, index) => {
          headList.push(
            { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
          )
        })
        const allRows = [headList]
        data.map((report, index) => {
          const newRow = [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? report.dealer_name_bn : report.dealer_name, style: 'td', alignment: 'center' },
            { text: getMonth(report.month), style: 'td', alignment: 'center' }
          ]
          fertilizerNameList.map((item, index) => {
            if (report.fertilizer_id === item.value) {
              newRow.push(
                { text: vm.$n(report.total_quantity), style: 'th', alignment: 'right' }
              )
            } else {
              newRow.push({})
            }
          })
          allRows.push(newRow)
        })
        const footList = [
          { text: vm.$t('fertilizerReport.GrandTotalMTon'), style: 'th', colSpan: 3, alignment: 'right', bold: true },
          {},
          {}
        ]
        fertilizerNameList.map((item, index) => {
          footList.push(
            { text: vm.$n(getTotalData(data, item.value)), style: 'th', alignment: 'right', bold: true }
          )
        })
        allRows.push(footList)
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '25%', '20%', '15%', '15%', '15%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 10]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 15, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('dealer-allocation-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
