<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.dealer_allocation_report') }}</h4>
        </template>
      <template v-slot:body>
        <b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
          <b-row lg="6" sm="12">
            <b-col>
              <ValidationProvider name="Allocation Type"  vid="allocation_type_id" rules= "required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="allocation_type_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('fertilizerSales.allocationType')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    v-model="search.allocation_type_id"
                    :options="allocationTypeList"
                    id="allocation_type_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules= "required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="fiscal_year_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    v-model="search.fiscal_year_id"
                    :options="fiscalYearList"
                    id="fiscal_year_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <ValidationProvider name="Month"  vid="month_id">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="month_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('fertilizerSales.month')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.month"
                    :options="monthList"
                    id="month_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row lg="6" sm="12">
            <b-col>
              <ValidationProvider name="Region"  vid="region_id" rules= "required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="region_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.region') }}<span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    v-model="search.region_id"
                    :options="regionList"
                    id="region_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <ValidationProvider name="District" vid="district_id">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.district')"
                label-for="district_id"
                slot-scope="{ valid, errors }"
                >
                  <b-form-select
                  plain
                  v-model="search.district_id"
                  :options="districtList"
                  id="district_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <ValidationProvider name="Fertilizer"  vid="fertilizer_id">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="fertilizer_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('fertilizerConfig.fertilizer_name')}}  <span class="text-danger"></span>
                  </template>
                  <b-form-select
                    plain
                    v-model="search.fertilizer_id"
                    :options="fertilizerNameList"
                    id="fertilizer_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" sm="12">
              <ValidationProvider name="DealerList"  vid="dealer_id">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="dealer_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('fertilizerReport.dealer_name')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.dealer_id"
                    :options="dealerList"
                    id="dealer_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="8">
                <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
            <b-col></b-col>
          </b-row>
        </b-form>
        </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
      <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('fertilizerReport.dealer_allocation_report') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button class="btn_add_new" @click="pdfExport">
                <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                <b-row v-show="!loading">
                  <b-col>
                      <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                        {{ $t('fertilizerReport.dealer_allocation_report')}}
                      </list-report-head>
                  </b-col>
                </b-row>
                <div class="text-center">
                  <table style="width:100%;color:black;">
                    <tr>
                      <td align="right" style="width:40%">{{$t('fertilizerSales.allocationType')}}</td>
                      <td align="center" style="width:5%">:</td>
                      <td align="left" style="width:45%">{{ search.allo }}</td>
                    </tr>
                    <tr v-if="search.monthList.length">
                      <td align="right" style="width:40%">{{$t('fertilizerSales.allocationMonth')}}</td>
                      <td align="center" style="width:5%">:</td>
                      <td align="left" style="width:45%">{{ search.monthList }}</td>
                    </tr>
                    <tr v-if="search.YearList">
                      <td align="right" style="width:40%">{{$t('fertilizerSales.allocationYear')}}</td>
                      <td align="center" style="width:5%">:</td>
                      <td align="left" style="width:45%">{{ search.YearList }}</td>
                    </tr>
                  </table>
                </div>
                <br/>
                <b-row>
                  <div class="col text-right font-weight-bold">
                    <span class="text-right text-dark"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                  </div>
                </b-row>
                <b-row>
                  <b-col md="12" class="table-responsive">
                    <b-overlay v-show="showData">
                      <div>
                        <b-table-simple bordered hover small caption-top responsive>
                          <colgroup><col><col></colgroup>
                          <colgroup><col><col><col></colgroup>
                          <colgroup><col><col></colgroup>
                          <b-thead>
                            <b-tr>
                              <b-td style="width:10%;text-align:center">{{ $t('globalTrans.sl_no') }}</b-td>
                              <b-td style="width:20%;text-align:center">{{ $t('fertilizerReport.dealer_name') }}</b-td>
                              <b-td style="width:20%;text-align:center">{{ $t('fertilizerSales.month') }}</b-td>
                              <b-td class="text-right" v-for="(item, index) in fertilizerNameList" :key="index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-td>
                            </b-tr>
                          </b-thead>
                          <b-tbody  v-for="(info, index) in dealerData" :key="index">
                            <template>
                              <b-tr>
                                <b-td style="text-align:center">{{ $n(index+1) }}</b-td>
                                <b-td style="text-align:center">{{ ($i18n.locale === 'bn') ? info.dealer_name_bn : info.dealer_name }}</b-td>
                                <b-td style="text-align:center">{{ getMonth(info.month) }}</b-td>
                                <b-td class="text-right" v-for="(item, index) in fertilizerNameList" :key="index">
                                  <slot v-if="info.fertilizer_id === item.value">{{ $n(info.total_quantity) }} </slot>
                                </b-td>
                              </b-tr>
                              </template>
                          </b-tbody>
                          <b-tfoot v-if="dealerData.length">
                            <b-tr>
                              <b-th colspan="3"  class="text-right">{{ $t('fertilizerReport.GrandTotalMTon') }}  <b></b>
                              </b-th>
                              <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="index">
                                {{ $n(getTotalData(dealerData, item.value))}}
                              </b-th>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                    </div>
                    </b-overlay>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, dealerAllocationReport } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import ListReportHead from '.././ListReportHead.vue'

export default {
  name: 'UiDataTable',
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      showData: false,
      regionTable: false,
      withFerlizer: false,
      search: {
        fiscal_year_id: 0,
        region_id: 0,
        month: 0,
        allocation_type_id: 0,
        org_id: 3,
        fertilizer_id: 0,
        district_id: 0,
        dealer_id: 0,
        monthList: '',
        YearList: '',
        region: '',
        allo: ''
      },
      month: '',
      dealerData: {},
      organization: [],
      testId: 0,
      daptotal: 0,
      moptotal: 0,
      tsptotal: 0,
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    regionList: function () {
       return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    dealerList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    formTitle () {
       return this.$t('fertilizerReport.ministry_allocation_report')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    districtList: function () {
      return this.$store.state.commonObj.districtList
    },
    fiscalYearList: function () {
        const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const tempData = fiscalYears.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    monthList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    },
    allocationTypeLists: function () {
        const list = [
          { value: 1, text: 'Incentive' },
          { value: 2, text: 'Monthly' },
          { value: 3, text: 'Special/Additional' }
        ]
        const listbn = [
          { value: 1, text: 'ইন্সেন্টিভ' },
          { value: 2, text: 'মাসিক' },
          { value: 3, text: 'বিশেষ/অতিরিক্ত' }
        ]
        return this.$i18n.locale === 'bn' ? listbn : list
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
        this.search.allo = this.allocationTypeList.filter(item => item.value === this.search.allocation_type_id)[0] ? this.allocationTypeList.filter(item => item.value === this.search.allocation_type_id)[0].text : ''
        this.search.region = this.regionList.filter(item => item.value === this.search.region_id)[0] ? this.regionList.filter(item => item.value === this.search.region_id)[0].text : ''
        this.search.monthList = this.monthList.filter(item => item.value === this.search.month)[0] ? this.monthList.filter(item => item.value === this.search.month)[0].text : ''
        this.search.YearList = this.fiscalYearList.filter(item => item.value === this.search.fiscal_year_id)[0] ? this.fiscalYearList.filter(item => item.value === this.search.fiscal_year_id)[0].text : ''
        this.showData = true
        this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, dealerAllocationReport, params).then(response => {
        if (response.success) {
            this.month = this.getMonth(response.data.month)
            this.dealerData = this.getRelationalData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const dealerList = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList
      const allDatas = []
      data.forEach((element, key) => {
        element.month_name = this.getMonth(element.month)
        element.month_name_bn = this.getMonth(element.month)
        const dealerObject = dealerList.find(dealer => dealer.value === element.dealer_id)
        element.dealer_name = dealerObject !== undefined ? dealerObject.text_en : ''
        element.dealer_name_bn = dealerObject !== undefined ? dealerObject.text_bn : ''

        allDatas[key] = element
      })
      return allDatas
    },
    getTotalData (data, fertilizerId) {
      let total = 0
      data.forEach((element, key) => {
        if (element.fertilizer_id === fertilizerId) {
          total += element.total_quantity
        }
      })
      return total
    },
    getMonth (month) {
      month = parseInt(month)
      if (month === 1) {
        return this.$i18n.locale === 'bn' ? 'জুলাই' : 'July'
      } else if (month === 2) {
        return this.$i18n.locale === 'bn' ? 'আগস্ট' : 'August'
      } else if (month === 3) {
        return this.$i18n.locale === 'bn' ? 'সেপ্টেম্বর' : 'September'
      } else if (month === 4) {
        return this.$i18n.locale === 'bn' ? 'অক্টোবর' : 'October'
      } else if (month === 5) {
        return this.$i18n.locale === 'bn' ? 'নভেম্বর' : 'November'
      } else if (month === 6) {
        return this.$i18n.locale === 'bn' ? 'ডিসেম্বর' : 'December'
      } else if (month === 7) {
        return this.$i18n.locale === 'bn' ? 'জানুয়ারী' : 'January'
      } else if (month === 8) {
        return this.$i18n.locale === 'bn' ? 'ফেব্রুয়ারী' : 'February'
      } else if (month === 9) {
        return this.$i18n.locale === 'bn' ? 'মার্চ' : 'March'
      } else if (month === 10) {
        return this.$i18n.locale === 'bn' ? 'এপ্রিল' : 'April'
      } else if (month === 11) {
        return this.$i18n.locale === 'bn' ? 'মে' : 'May'
      } else if (month === 12) {
        return this.$i18n.locale === 'bn' ? 'জুন' : 'June'
      }
    },
    pdfExport () {
      const reportTitle = this.$t('fertilizerReport.dealer_allocation_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.dealerData, this, this.search, this.fertilizerNameList)
    }
    // pdfExport () {
    //   const rowData = this.getPdfData()

    //   const labels = [
    //       { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
    //       { text: this.$t('fertilizerReport.dealer_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
    //       { text: this.$t('fertilizerSales.month'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
    //       { text: this.$t('fertilizerReport.TSP'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
    //       { text: this.$t('fertilizerReport.MOP'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
    //       { text: this.$t('fertilizerReport.DAP'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
    //     ]
    //   rowData.unshift(labels)
    //   const reportTitle = this.$i18n.locale === 'en' ? 'Dealer Wise Allocation Report' : 'ডিলার অনুযায়ী বরাদ্দ প্রতিবেদন'
    //     const columnWids = ['10%', '20%', '20%', '20%', '20%', '10%']
    //   ExportPdf.exportPdf(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, rowData, columnWids)
    // },
    // getPdfData () {
    //     const keys = [
    //       { key: 'serial_no' },
    //       { key: this.$i18n.locale === 'en' ? 'dealer_name' : 'dealer_name_bn' },
    //       { key: 'month' },
    //       { key: 'total_quantity' },
    //       { key: 'total_quantity' },
    //       { key: 'total_quantity' }
    //     ]
    //   var serial = 0
    //   const listData = this.dealerData.map(item => {
    //     serial += 1
    //     const rowData = keys.map((keyItem, index) => {
    //         if (keyItem.key === 'serial_no') {
    //             return { text: this.$n(serial) }
    //         }
    //         if (keyItem.key === 'month') {
    //             return { text: this.getMonth(item[keyItem.key]) }
    //         }

    //       return { text: item[keyItem.key] }
    //     })

    //     return rowData
    //   })

    //   return listData
    // }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
